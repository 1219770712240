import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import { StaticImage } from "gatsby-plugin-image"
import MVVideoModal from "../../components/MVVideoModal"

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  & video {
    width: 100%;
  }
  .play-button {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    translate: trasform(-50%, -50%);
    width: 60px;
    height: 60px;
    &:hover {
      cursor: pointer;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`
class Video extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
    }
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }

  openVideoModal() {
    this.setState({
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  render() {
    return (
      <>
        <LazyLoad>
          <MVVideoModal
            isOpen={this.state.isVideoModalOpen}
            onClose={this.closeVideoModal}
          />
        </LazyLoad>
        <LazyLoad>
            <VideoWrap>
            <StaticImage
                src="../../images/video-poster.png"
                alt="video poster"
            />
            <div
                className="play-button"
                role="button"
                tabIndex={0}
                onClick={this.openVideoModal}
                onKeyDown={e => {
                if (e.key === "Enter" || e.key === " ") {
                    this.openVideoModal()
                }
                }}
            >
                <StaticImage
                src="../../images/play-button.png"
                alt="play button"
                />
            </div>
            </VideoWrap>
        </LazyLoad>
      </>
    )
  }
}

export default Video
